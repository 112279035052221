/* .purchase--node-detail {
    padding: 10px;
    border-radius: 3px;
    font-size: 12px;
    color: #222;
    height: 100%;
    width: 360px;
    border-width: 1px;
    border-style: solid;
    border-color: #1a192b;
    background-color: white;
} */

.react-flow__edge-path {
    stroke: #303f49 !important;
}
