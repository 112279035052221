button.ant-btn.user-info {
    background-color: transparent !important;
    border: none;
    text-transform: none;
    box-shadow: none;
    position: relative;
}

button.ant-btn.user-info:hover {
    background-color: transparent !important;
    border: none;
    text-transform: none;
    box-shadow: none;
    position: relative;
}

button.user-info > span.ant-avatar {
    margin-right: 10px;
}

button.user-info > .anticon-user {
    background-color: rgba(255, 255, 255, 0.075);
    font-size: 20px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    padding-top: 8px;
    position: relative;
    margin: -5px 0 0 15px;
    margin-right: 10px;
}